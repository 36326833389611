import { BOM_TOOL } from "#imports";

export default {
    getDisplayPricing: function (distResult, quantity) {
        var best = null;
        if (distResult && distResult.Prices && distResult.Prices.length) {
            for (var i = 0; i < distResult.Prices.length; i++) {
                var price = distResult.Prices[i];
                if (price.Quantity <= quantity || best == null) {
                    best = price;
                }
            }
        }

        return best;
    },
    selectionMatchesResult: function (distResult, selection) {
        // Can't select anything that is not in stock
        if (selection.DistributorId == distResult.Distributor.Id && distResult.QuantityOnHand) {
            if (selection.DistributorPartNumber) {
                // If were specifying a result by dist part number, use that result
                return selection.DistributorPartNumber == distResult.DistributorPartNumber;
            } else {
                // Othwerwise, ensure we use the non duplicate result.
                return !distResult.IsDistributorDuplicate;
            }
        } else {
            return false;
        }
    },
    getAnonBOM: function () {
        if (localStorage) {
            var bomJson = localStorage.getItem("ecia-bom-anonymous");
            if (bomJson) {
                return JSON.parse(bomJson);
            }
        }
    },
    getAnonBomNextPartId: function (bom) {
        if (!bom.Parts || bom.Parts.length == 0) {
            return 0;
        }
        var maxId = bom.Parts.reduce(function (prev, current) {
            return prev.Id > current.Id ? prev : current;
        }).Id;
        return isNaN(maxId) ? 0 : maxId + 1;
    },
    setAnonBOM: function (bom) {
        var record = JSON.parse(JSON.stringify(bom));
        if (record && record.Parts && record.Parts.length) {
            for (let part of record.Parts) {
                // delete local attrs and things that should come from the server.
                delete part.loading;
                delete part.loaded;
                delete part.DistributorResults;
                delete part.PartDetail;
                delete part.LifecycleRisk;
                delete part.SupplyChainRisk;
            }
        }

        if (localStorage) {
            localStorage.setItem("ecia-bom-anonymous", JSON.stringify(record));
        }
    },
    getSortOrderFunction: function (bom) {
        switch (bom.SortOrder) {
            case BOM_TOOL.SORT_ORDER.Custom:
            default:
                return function (a, b) {
                    return a.SortNum - b.SortNum;
                };

            case BOM_TOOL.SORT_ORDER.PartNum_Asc:
                return function (a, b) {
                    var partA = a.PartNumber.toUpperCase();
                    var partB = b.PartNumber.toUpperCase();

                    if (partA < partB) {
                        return -1;
                    }
                    if (partA > partB) {
                        return 1;
                    }

                    return 0;
                };

            case BOM_TOOL.SORT_ORDER.PartNum_Desc:
                return function (a, b) {
                    var partA = a.PartNumber.toUpperCase();
                    var partB = b.PartNumber.toUpperCase();

                    if (partA > partB) {
                        return -1;
                    }
                    if (partA < partB) {
                        return 1;
                    }

                    return 0;
                };

            case BOM_TOOL.SORT_ORDER.Manufacturer_Asc:
                return function (a, b) {
                    var mfrA = (a.ManufacturerName || "zzz").toUpperCase();
                    var mfrB = (b.ManufacturerName || "zzz").toUpperCase();

                    if (mfrA < mfrB) {
                        return -1;
                    }
                    if (mfrA > mfrB) {
                        return 1;
                    }

                    return 0;
                };

            case BOM_TOOL.SORT_ORDER.Manufacturer_Desc:
                return function (a, b) {
                    var mfrA = (a.ManufacturerName || "zzz").toUpperCase();
                    var mfrB = (b.ManufacturerName || "zzz").toUpperCase();

                    if (mfrA > mfrB) {
                        return -1;
                    }
                    if (mfrA < mfrB) {
                        return 1;
                    }

                    return 0;
                };
        }
    },
    getDefaultPageSize: function () {
        var defaultPageSize = BOM_TOOL.DEFAULT_PAGE_SIZE;
        if (localStorage) {
            var setPageSize = localStorage.getItem("ecia-bom-pagesize");
            var setPageSizeInt = parseInt(setPageSize, 10);
            if (setPageSize && setPageSizeInt < defaultPageSize) {
                defaultPageSize = setPageSizeInt;
            }
        }

        return defaultPageSize;
    },
};
